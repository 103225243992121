import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeScene from '@/scenes/Home';
import { initializeRootStore } from '@/stores';
import { getEnv, getSnapshot } from 'mobx-state-tree';

interface HomeProps {
  pageContent: any;
}

const Home = ({ pageContent }: HomeProps) => {
  return <HomeScene content={pageContent} />;
};

export const getStaticProps = async () => {
  const rootStore = initializeRootStore();

  await rootStore.bearsStore.loadFeaturedBears();
  await rootStore.storiesStore.loadItems();

  const pageContent = await getEnv(rootStore).strapiService.get('/home-page', {
    params: {
      'populate[banner][populate]': '*',
      'populate[aboutSection][populate]': '*',
      'populate[clubSection][populate]': '*',
      'populate[abominationsSection][populate]': '*',
      'populate[featuredBearsSection][populate]': '*',
      'populate[storiesSection][populate]': '*',
      'populate[bearpathSection][populate]': '*',
      'populate[team][populate]': '*',
      'populate[cta][populate]': '*',
    },
  });

  return {
    props: {
      initialState: getSnapshot(rootStore),
      pageContent: pageContent.data.data.attributes,
    },
  };
};

export default Home;
